import React, { useEffect, useState } from 'react';
import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
// import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AdministerModal from '../modals/administer_modal';
import ProlongContractModal from '../modals/prolong_contract_modal';
import BuybackYourPriceModal from '../modals/buyback_your_price_modal';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table/dist';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { contractIsOnRecurring, formatDKK } from '../../../utils/common';
import RecurringSignupModal from '../modals/recurring_signup_modal';
import { FormattedMessage } from 'react-intl';

function createData(
    recurring,
    id,
    status,
    items,
    repurchase,
    payout,
    month_prolonging_pr,
    buyback_pr_today,
    owed_amount,
    // buyback_pr_today_repurchase,
    // administer,
    days_until_repurchase,
    total_owed_amount
) {
    return {
        recurring,
        id,
        status,
        items,
        repurchase,
        payout,
        month_prolonging_pr,
        buyback_pr_today,
        owed_amount,
        // buyback_pr_today_repurchase,
        // administer,
        days_until_repurchase,
        total_owed_amount,
    };
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 430,
    },
});

const formatRecurringPayment = (contractData, onclick) => {
    if (!contractData.monthlyPayment) {
        return '-';
    }
    const expired = contractData.daysUntilRepurchase < 0;
    if (contractIsOnRecurring(contractData)) {
        return (
            <div>
                {contractData?.recurringMethod?.indexOf('reepay') > -1 ? (
                    <div className="text-success small">
                        <FormattedMessage id="contracts.activeReepayRecurring" defaultMessage="Signed up with credit card" />
                    </div>
                ) : (
                    <div className="text-success small">
                        <FormattedMessage id="contracts.activeBSRecurring" defaultMessage="Signed up with Betalingsservice" />
                    </div>
                )}
            </div>
        );
    } else if (contractData?.recurringStatus === 'pending') {
        return (
            <div>
                <div className={`${expired ? 'text-dark' : 'text-danger'} small`}>
                    <FormattedMessage id="contracts.pendingRecurring" defaultMessage="Pending" />
                </div>
                <button className={`btn btn-black-cust btn-small`} onClick={onclick}>
                    <FormattedMessage id="signup" defaultMessage="Sign up" />
                </button>
            </div>
        );
    } else {
        return (
            <div>
                <div className={`${expired ? 'text-dark' : 'text-danger'} small`}>
                    <FormattedMessage id="contracts.noRecurring" defaultMessage="Not signed up" />
                </div>
                <button className={`btn btn-black-cust btn-small`} onClick={onclick}>
                    <FormattedMessage id="signup" defaultMessage="Sign up" />
                </button>
            </div>
        );
    }
};

function ActiveContractsTable(props) {
    const [reqPaymentDetails, setReqPaymentDetails] = useState({
        buybackPrice: '',
        buybackPriceCard: '',
        monthlyPayment: '',
        monthlyPaymentCard: '',
        contractId: '',
    });
    const [administerRequestDetails, setAdministerRequestDetails] = useState({
        contractId: '',
    });
    const [recurringSignupDetails, setRecurringSignupDetails] = useState({
        monthlyPayment: '',
        monthlyPaymentCard: '',
        contractId: '',
        accountNumber: '',
        registrationNumber: '',
        cpr: '',
    });

    let [dataUser, setDataUser] = useState({});
    let [activeContracts, setActiveContracts] = useState([]);
    let [lengthActiveContracts, setLengthActiveContracts] = useState(0);
    let [row_arr, setArr] = useState([]);

    const columns = [
        { id: 'recurring', label: 'Automatisk forlængelse', minWidth: 100, align: 'center' },
        { id: 'id', label: 'Kontrakt id', minWidth: 103, align: 'center' },
        { id: 'status', label: 'Status', minWidth: 151, align: 'center' },
        { id: 'items', label: 'Genstand(e)', minWidth: 147, align: 'center' },
        { id: 'repurchase', label: 'Seneste tilbagekøbsdato', minWidth: 148, align: 'center' },
        { id: 'payout', label: 'Udbetaling', minWidth: 150, align: 'center' },
        {
            id: 'month_prolonging_pr',
            label: 'Omkostning pr. måned',
            minWidth: 151,
            align: 'center',
        },
        { id: 'buyback_pr_today', label: 'Tilbagekøbspris i dag', minWidth: 147, align: 'center' },
        // {
        //     id: 'buyback_pr_today_repurchase',
        //     label: 'Tilbagekøbspris ved seneste tilbagekøbsdato',
        //     minWidth: 147,
        //     align: 'center',
        // },
        {
            id: 'owed_amount',
            label: 'Skyldigt beløb',
            minWidth: 151,
            align: 'center',
        },
        // { id: 'administer', label: 'Administrer', minWidth: 147, align: 'center' },
    ];
    let rows = [];

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    let [toggleModal3, setToggleModal3] = useState(false);
    let [toggleModal4, setToggleModal4] = useState(false);
    let [toggleModal5, setToggleModal5] = useState(false);
    let [toggleModal6, setToggleModal6] = useState(false);

    const handleBuyBack = (buybackPrice, buybackPriceCard, contractId) => {
        setReqPaymentDetails({
            buybackPrice: buybackPrice,
            buybackPriceCard: buybackPriceCard,
            contractId: contractId,
        });
        setToggleModal5(!toggleModal5);
    };

    const handleProlonging = (monthlyPayment, monthlyPaymentCard, contractId) => {
        setReqPaymentDetails({
            monthlyPayment: monthlyPayment,
            monthlyPaymentCard: monthlyPaymentCard,
            contractId: contractId,
        });
        setToggleModal4(!toggleModal4);
    };

    const handleRecurringSignup = (monthlyPayment, contractId, accountNumber, registrationNumber, cpr) => {
        setRecurringSignupDetails({
            monthlyPayment,
            monthlyPaymentCard: Math.round(monthlyPayment),
            contractId,
            accountNumber,
            registrationNumber,
            cpr,
        });
        setToggleModal6(!toggleModal6);
    };

    const handleAdministerRecurring = (contractId) => {
        setAdministerRequestDetails({
            contractId: contractId,
        });
        setToggleModal3(!toggleModal3);
    };

    let response = props.onGetData;

    useEffect(() => {
        setDataUser(response);
        setActiveContracts(response);
        setLengthActiveContracts(response.pantsatContracts.length);
        let arr = [];
        arr = response.pantsatContracts.map((data, index) => {
            const title =
                data.items
                    .map((item) => (!item.status.includes('olgt') ? item.title : ''))
                    .filter((title) => title)
                    .join(', ') + '\n';
            return createData(
                formatRecurringPayment(data, () =>
                    handleRecurringSignup(data.monthlyPayment, data.contractId, response.accountNumber, response.registrationNumber, response.cpr)
                ),
                data.contractId,
                data.customerPortalStatus,
                title,
                new Date(data.latestRepurchaseDate).toLocaleDateString(),
                formatDKK(data.regulatedPayout, 0),
                (() => {
                    if (
                        data.customerPortalStatus?.toLowerCase().indexOf('solgt') > 0 ||
                        data.customerPortalStatus?.toLowerCase().indexOf('sold') > 0
                    ) {
                        return '-';
                    } else {
                        return (
                            <div>
                                <div>{formatDKK(data.monthlyPayment, 0)}</div>
                                {/* <button
                                    className={`btn btn-black-cust btn-small ${data.monthlyPayment && data.daysUntilRepurchase < 0 ? '' : 'd-none'}`}
                                    onClick={() =>
                                        handleProlonging(data.monthlyPayment, Math.round(data.monthlyPayment * 1.02 * 100) / 100, data.contractId)
                                    }
                                >
                                    <FormattedMessage id="prolongNow" defaultMessage="Prolong now" />
                                </button> */}
                            </div>
                        );
                    }
                })(),
                // (() => {
                //     if (data.customerPortalStatus?.toLowerCase().indexOf('(') > 0)
                //         return <FormattedMessage id="repurchased" defaultMessage="Repurchased" />;
                //     else if (
                //         data.customerPortalStatus?.toLowerCase().indexOf('videresalg') > 0 ||
                //         data.customerPortalStatus?.toLowerCase().indexOf('sale') > 0
                //     ) {
                //         return '-';
                //     } else {
                //         return data.daysUntilRepurchase < 0 ? (
                //             <FormattedMessage id="expiredCaps" defaultMessage="EXPIRED" />
                //         ) : (
                //             // `${formatDKK(data.buybackPriceOnExpiration, 0)}`
                //             `${formatDKK(data.buybackPrice, 0)}`
                //         );
                //     }
                // })(),
                (() => {
                    if (data.customerPortalStatus?.toLowerCase().indexOf('solgt') > 0 || data.customerPortalStatus?.toLowerCase().indexOf('sold') > 0)
                        return <FormattedMessage id="repurchased" defaultMessage="Repurchased" />;
                    // else if (
                    //     data.customerPortalStatus?.toLowerCase().indexOf('videresalg') > 0 ||
                    //     data.customerPortalStatus?.toLowerCase().indexOf('sale') > 0
                    // ) {
                    //     return '-';
                    // }
                    else {
                        return (
                            <div key={data.itemId}>
                                <div>{formatDKK(data.buybackPrice, 0)}</div>
                                <button
                                    className={`btn btn-black-cust btn-small ${data.buybackPrice ? '' : 'd-none'}`}
                                    onClick={() => handleBuyBack(data.buybackPrice, Math.round(data.cardBuybackPrice * 100) / 100, data.contractId)}
                                >
                                    <FormattedMessage id="buybackNow" defaultMessage="Buyback now" />
                                </button>
                            </div>
                        );
                    }
                })(),
                (() => {
                    const owedAmount = data.owedAmount || data.owedAmountAllActive;
                    if (owedAmount) {
                        return (
                            <div>
                                {' '}
                                <div>{formatDKK(owedAmount, 0)}</div>
                                <button
                                    className={`btn btn-black-cust btn-small ${data.monthlyPayment && data.daysUntilRepurchase < 0 ? '' : 'd-none'}`}
                                    onClick={() => handleProlonging(owedAmount, Math.round(owedAmount * 1.02 * 100) / 100, data.contractId)}
                                >
                                    <FormattedMessage id="prolongNow" defaultMessage="Prolong now" />
                                </button>
                            </div>
                        );
                    } else {
                        return <div>{formatDKK(0, 0)}</div>;
                    }
                })(),

                // <div>Kommer snart</div>,
                // <button className="btn btn-black-cust"
                //         onClick={() => handleAdministerRecurring(data.contractId)}>Administer</button>
                data.daysUntilRepurchase,
                data.owedAmount > data.owedAmountAllActive ? data.owedAmount : data.owedAmountAllActive
            );
        });
        setArr(arr);
    }, []);

    return (
        <>
            <div className={`${row_arr.filter((r) => r.days_until_repurchase < 0).length > 0 ? '' : 'd-none'} mb-3 border bg-danger p-3`}>
                <FormattedMessage id="youHave" defaultMessage="You have" />
                {` ${row_arr.filter((r) => r.days_until_repurchase < 0).length} `}
                <FormattedMessage id="expiredContracts" defaultMessage="contracts that is expired. If you don't renew them, they will be sold." />
            </div>
            <div className="table-ui mb-5">
                <Paper className={classes.root}>
                    <div className="web-table">
                        <TableContainer className={classes.container}>
                            <Table aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    // minWidth: column.minWidth ? column.minWidth : 1, // Specify the CSS property
                                                    background: '#ECECEC',
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row_arr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        return (
                                            <TableRow
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={`row-${index}-${row.id}`}
                                                className={row.days_until_repurchase < 0 ? 'expired' : ''}
                                            >
                                                {columns.map((column, colIndex) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={`cell-${index}-${colIndex}`} align={column.align}>
                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={lengthActiveContracts}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    /> */}
                    </div>
                    <div className="mobile-table">
                        <Table>
                            <Thead>
                                <Tr>
                                    {columns.map((column) => (
                                        <Th key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                            {column.label}
                                        </Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {row_arr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                    return (
                                        <Tr
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={`row-${index}-${row.id}`}
                                            className={row.days_until_repurchase < 0 ? 'expired' : ''}
                                        >
                                            {columns.map((column, colIndex) => {
                                                const value = row[column.id];
                                                return (
                                                    <Td key={`cell-${index}-${colIndex}`} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </Td>
                                                );
                                            })}
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                        {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={lengthActiveContracts}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    /> */}
                    </div>
                </Paper>

                <AdministerModal title={''} onPress={setToggleModal3} show={toggleModal3} contractId={administerRequestDetails.contractId} />
                <ProlongContractModal
                    title={'Forlæng din kontrakt'}
                    onPress={setToggleModal4}
                    show={toggleModal4}
                    monthlyPayment={formatDKK(reqPaymentDetails.monthlyPayment, 0)}
                    contractId={reqPaymentDetails.contractId}
                    cardAmount={reqPaymentDetails.monthlyPaymentCard}
                />
                <BuybackYourPriceModal
                    title={'Tilbagekøb'}
                    onPress={setToggleModal5}
                    show={toggleModal5}
                    buybackPrice={formatDKK(reqPaymentDetails.buybackPrice, 0)}
                    contractId={reqPaymentDetails.contractId}
                    cardAmount={reqPaymentDetails.buybackPriceCard}
                />
                <RecurringSignupModal
                    title={'Automatisk betaling'}
                    onPress={setToggleModal6}
                    show={toggleModal6}
                    monthlyPayment={recurringSignupDetails.monthlyPayment}
                    cardAmount={recurringSignupDetails.monthlyPaymentCard}
                    registrationNumber={recurringSignupDetails.registrationNumber}
                    accountNumber={recurringSignupDetails.accountNumber}
                    cpr={recurringSignupDetails.cpr}
                    contractId={recurringSignupDetails.contractId}
                />
            </div>
        </>
    );
}

export default ActiveContractsTable;
